<!--退单费用 modal-->
<template>
  <Modal class-name="i-modal" v-model="isShow" title="退单费用" width="800">
    <Form :label-width="80">
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="会员编号:">
            <Input v-model="formData.MemberCode" disabled />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="订单编号:">
            <Input v-model="formData.OrderCode" disabled />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="手续费:">
            <Input v-model="formData.handleCost" @on-blur="handleCalculate" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="物流费:">
            <Input
              v-model="formData.logisticsCost"
              @on-blur="handleCalculate"
            />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="可退金额:">
            <Input v-model="formData.canRefund" disabled />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="实退金额:">
            <Input v-model="formData.realRefund" disabled />
          </FormItem>
        </Col>
      </Row>
      <FormItem label="系统备注:">
        <Input type="textarea" v-model="formData.sysRemark" />
      </FormItem>
      <FormItem label="备注:">
        <tinymce-editor ref="tinymce" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Poptip confirm title="您确认取消这条订单吗？" @on-ok="handleRefund">
        <Button type="primary">取消该订单</Button>
      </Poptip>
      <Button @click="isShow = false" class="ml8">取消</Button>
    </div>
  </Modal>
</template>

<script>
import api from "@/api";
import tinymceEditor from "@/components/tinymce-editor/index.vue";
export default {
  name: "cancel-order-modal",
  data() {
    return {
      isShow: false,
      formData: {
        orderid: "",
        logisticsCost: "",
        handleCost: "",
        sysRemark: "",
        isDeduction: false,
        customerRemark: "",
        canRefund: "",
        realRefund: "",
        MemberCode: "",
        OrderCode: "",
      },
      propIsDeduction: false,
      OrderType: 0,
    };
  },
  watch: {
    isShow(val) {
      if (!val) {
        this.formData.sysRemark = this.formData.customerRemark = "";
        this.$refs.tinymce.tinymceHtml = "";
      }
    },
    propIsDeduction(val) {
      if (val) this.formData.isDeduction = true;
    },
  },
  methods: {
    async handleRefund() {
      try {
        this.formData.customerRemark = this.$refs.tinymce.tinymceHtml;
        const res = await api.CancelOrCustomerOrder(this.formData);
        if (res.Status === 100 && res.Msg) {
          this.$Message.success(res.Msg);
        }
        this.$emit("cancleOk");
        this.isShow = false;
      } catch (err) {
        this.$Message.error(err);
      }
    },
    handleCalculate() {
      const result =
        this.$big.minus(
          this.formData.canRefund,
          this.$big.plus(this.formData.handleCost, this.formData.logisticsCost)
        ) * 1;
      if (result < 0) {
        this.$Message.error("手续费和物流费之和不得大于支付金额!");
      } else {
        this.formData.realRefund = result;
      }
    },
  },
  components: { tinymceEditor },
};
</script>

<style lang="less"></style>
