<template>
  <Modal
    class-name="i-modal"
    title="部分退货"
    v-model="isShow"
    :width="800"
    loading
    :styles="{ top: '20px' }"
  >
    <Form :model="formData" :label-width="80">
      <Row>
        <i-col span="12">
          <FormItem label="手续费：">
            <Input v-model="formData.handleCost" />
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="物流费：">
            <Input v-model="formData.logisticCost" />
          </FormItem>
        </i-col>
      </Row>
      <Row>
        <i-col span="24">
          <Table
            class="mt10 main-table"
            :height="200"
            ref="goodTable"
            :columns="productOptions"
            :data="formData.list"
            highlight-row
            size="small"
          >
            <template slot="Image" slot-scope="{ row }">
              <Avatar
                shape="square"
                :src="$setImagePrefix(row.DefaultImagePath)"
              />
            </template>
          </Table>
        </i-col>
      </Row>
    </Form>
    <div slot="footer">
      <Button type="default" @click="isShow = false">取消</Button>
      <Button type="primary" :loading="asyncLoading" @click="handleReturn"
        >确定</Button
      >
    </div>
  </Modal>
</template>

<script>
import api from "@/api";
export default {
  name: "return-good-modal",
  data() {
    return {
      asyncLoading: false,
      isShow: false,
      formData: {
        handleCost: 0,
        logisticCost: 0,
        list: [],
      },
      productOptions: [
        {
          title: "商品图片",
          key: "DefaultImagePath",
          slot: "Image",
          width: 80,
        },
        { title: "商品编号", key: "Code", minWidth: 100 },
        { title: "商品名称", key: "ProductName", minWidth: 160 },
        { title: "商品单价", key: "UnitPrice", width: 80, align: "center" },
        { title: "商品数量", key: "ProductCount", width: 80, align: "center" },
        { title: "已退数量", key: "RetiredNum", width: 80, align: "center" },
        {
          title: "退货数量",
          key: "ReturnNum",
          width: 100,
          align: "center",
          fixed: "right",
          slot: "action",
          render: (h, params) => {
            return h("div", [
              h("InputNumber", {
                props: {
                  value: 0,
                  max: params.row.ProductCount - params.row.RetiredNum,
                  min: 0,
                },
                on: {
                  "on-change": (e) => {
                    this.formData.list.find(
                      (item) => item.ID === params.row.ID
                    ).ReturnNum = e;
                  },
                },
              }),
            ]);
          },
        },
      ],
    };
  },
  watch: {
    isShow(val) {
      if (!val) this.formData.list = [];
    },
  },
  methods: {
    async handleReturn() {
      this.asyncLoading = true;
      try {
        await api.SectionReturn(this.formData);
        this.$Message.success("部分退货成功");
        this.$emit("returnOk");
        this.formData.list = [];
        this.asyncLoading = false;
        this.isShow = false;
      } catch (error) {
        this.asyncLoading = false;
      }
    },
  },
};
</script>

<style lang="less"></style>
