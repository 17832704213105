<template>
  <Drawer
    title="订单信息"
    class="order_detail_drawer"
    v-model="isShow"
    width="1200"
    scrollable
    draggable
  >
    <div class="drawer-container">
      <div class="drawer-content">
        <Row class="text-cell">
          <i-col class="title" span="2">订单编号:</i-col>
          <i-col class="value" span="6">{{ obj.OrderCode }}</i-col>
          <i-col class="title" span="2">会员姓名:</i-col>
          <i-col class="value" span="6">{{ obj.MemberName }}</i-col>
          <i-col class="title" span="2">订单状态:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.OrderStatusCN }}</span>
          </i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">收货人名称:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.ReceiveName }}</span>
          </i-col>
          <i-col class="title" span="2">收货人电话:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.ReceivePhone }}</span>
          </i-col>
          <i-col class="title" span="2">收货人地址:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.ReceiveAddress }}</span>
          </i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">物流公司:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.ExpressName ? obj.ExpressName : "未发货" }}</span>
          </i-col>
          <i-col class="title" span="2">物流单号:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.ShippingCode }}</span>
          </i-col>
          <i-col class="title" span="2">发货时间:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.ShippingDate }}</span>
          </i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">订单总金额:</i-col>
          <i-col class="value" span="6">{{ obj.TotalAmount }}</i-col>
          <i-col class="title" span="2">实付金额:</i-col>
          <i-col class="value" span="6">{{ obj.PayAmount }}</i-col>
          <i-col class="title" span="2">运费金额:</i-col>
          <i-col class="value" span="6">{{ obj.TotalFreight }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">会员编号:</i-col>
          <i-col class="value" span="6">{{ obj.MemberCode }}</i-col>
          <i-col class="title" span="2">运费类型:</i-col>
          <i-col class="value" span="6">{{ obj.FreightTypeCN }}</i-col>
          <i-col class="title" span="2">订单渠道:</i-col>
          <i-col class="value" span="6">{{ obj.ChannelCN }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">下单时间:</i-col>
          <i-col class="value" span="6">{{ obj.OrderDate }}</i-col>
          <i-col class="title" span="2">取消时间:</i-col>
          <i-col class="value" span="6">{{ obj.CancelDate }}</i-col>
          <i-col class="title" span="2">支付时间:</i-col>
          <i-col class="value" span="6">{{ obj.PayDate }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">完成时间:</i-col>
          <i-col class="value" span="6">{{ obj.FinishDate }}</i-col>
          <i-col class="title" span="2">订单类型:</i-col>
          <i-col class="value" span="6">{{ obj.OrderTypeCN }}</i-col>
          <i-col class="title" span="2">取消原因:</i-col>
          <i-col class="value" span="6">{{ obj.CancelReason }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">出货状态:</i-col>
          <i-col class="value" span="6">{{ obj.OutStockStatusCN }}</i-col>
          <i-col class="title" span="2">订单备注:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.Remark }}</span>
          </i-col>
          <i-col class="title" span="2">订单系统备注:</i-col>
          <i-col class="value" span="6">
            <span>{{ obj.SysRemark }}</span>
          </i-col>
        </Row>

        <Divider orientation="left">
          商品信息
          <Icon
            v-show="!showProductTable"
            @click="showProductTable = !showProductTable"
            type="md-arrow-dropup-circle"
          />
          <Icon
            v-show="showProductTable"
            @click="showProductTable = !showProductTable"
            type="md-arrow-dropdown-circle"
          />
        </Divider>
        <Table
          class="mt10 main-table"
          :columns="productOptions"
          :data="productData"
          v-show="showProductTable"
          :row-class-name="RowClassName"
          highlight-row
          size="small"
        >
          <template slot="Image" slot-scope="{ row }">
            <div style="position: relative; width: 32px; height: 32px">
              <Avatar shape="square" :src="$setImagePrefix(row.ProductImg)" />
              <span
                v-if="row.Type === 30"
                style="
                  position: absolute;
                  top: 0;
                  right: -16px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  background: red;
                  font-size: 12px;
                  padding: 2px 2px;
                "
                >错</span
              >
              <span
                v-if="row.Type === 20"
                style="
                  position: absolute;
                  top: 0;
                  right: -16px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  background: red;
                  font-size: 12px;
                  padding: 2px 2px;
                "
                >换</span
              >
              <span
                v-if="row.Type === 50"
                style="
                  position: absolute;
                  top: 0;
                  right: -16px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  background: green;
                  font-size: 12px;
                  padding: 2px 2px;
                "
                >赠</span
              >
            </div>
          </template>
          <template slot="RealShippingNum" slot-scope="{ row, index }">
            <Input
              type="text"
              v-model="row.RealShippingNum"
              v-if="editIndex === index"
            />
            <span v-else>{{ row.RealShippingNum }}</span>
          </template>
          <template slot="LossNum" slot-scope="{ row, index }">
            <Input
              type="text"
              v-model="row.LossNum"
              v-if="editIndex === index"
            />
            <span v-else>{{ row.LossNum }}</span>
          </template>
          <template slot-scope="{ row, index }" slot="action">
            <div v-if="editIndex === index">
              <Button type="success" @click="handleSave(index, row)"
                >保存</Button
              >
              <Button @click="editIndex = -1">取消</Button>
            </div>
            <div v-else>
              <Button
                type="primary"
                v-if="$canAction(['Order_Edit']) && obj.OrderStatus >= 40"
                @click="handleEdit(row, index)"
                >编辑</Button
              >
            </div>
          </template>
        </Table>
        <Divider orientation="left" v-if="ShowPaylog">支付记录</Divider>
        <Table
          :columns="payLogOptions"
          :data="payLogData"
          max-height="200"
          highlight-row
          class="mt10 main-table"
          v-if="ShowPaylog"
          size="small"
        >
          <template slot="action" slot-scope="{ row }">
            <Poptip
              transfer
              confirm
              title="您确定要退款吗？"
              @on-ok="handleRefundPayLog(row)"
            >
              <Button
                type="primary"
                v-if="
                  $canAction(['Order_RefundPayLog']) &&
                  (row.PayStatus == 2 || row.PayStatus == 4)
                "
                size="small"
                >退款</Button
              >
            </Poptip>
          </template>
        </Table>
        <Divider orientation="left" v-if="ShowRefund">退款记录</Divider>
        <Table
          class="mt10 main-table"
          :columns="refundLogOptions"
          :data="refundLogData"
          max-height="200"
          highlight-row
          v-if="ShowRefund"
          size="small"
        >
          <template slot="ServiceID" slot-scope="{ row }">
            <span
              style="color: #19be6b; cursor: pointer"
              @click="handleOpenAfterSalesService(row)"
              >{{ row.ServiceID }}</span
            >
          </template>
        </Table>
        <Divider orientation="left" v-if="ShowExpectBonus"
          >预估奖金记录</Divider
        >
        <Table
          :columns="expectBonusOptions"
          :data="expectBonusData"
          max-height="200"
          highlight-row
          class="mt10 main-table"
          v-if="ShowExpectBonus"
          size="small"
        >
        </Table>
      </div>

      <div class="drawer-footer">
        <!-- <div class="mr10"
             v-if="$route.name === 'ShippingCustomerList'"> -->
        <Button
          type="primary"
          class="mr10"
          v-if="
            $canAction(['Order_Export']) &&
            $route.name === 'ShippingCustomerList'
          "
          @click="handleExportExcel(obj)"
          >导出</Button
        >
        <Button
          type="primary"
          class="mr10"
          v-if="
            $canAction(['Order_Print']) &&
            $route.name === 'ShippingCustomerList'
          "
          @click="handlePrint(obj)"
          >打印</Button
        >
        <!-- </div> -->
        <Poptip
          v-if="
            $route.name === 'AllOrder' &&
            obj.OrderStatus !== 70 &&
            obj.OrderStatus !== 20
          "
          :title="
            obj.IsExport
              ? '该订单已导单是否确认取消?'
              : '您确认取消这条订单吗？'
          "
          confirm
          @on-ok="handleCancel(obj)"
        >
          <Button
            type="error"
            v-if="
              $canAction(['Order_Master_Cancel1']) &&
              obj.OrderStatus !== 20 &&
              obj.OrderStatus !== 70
            "
            class="ml2"
            >取消订单</Button
          >
        </Poptip>
        <Button
          type="warning"
          v-if="
            $route.name === 'AllOrder' &&
            obj.CanRefund == true &&
            obj.OrderStatus >= 30 &&
            obj.OrderStatus !== 70 &&
            $canAction(['Order_Master_Cancel'])
          "
          class="ml2"
          @click="handleReturn(obj)"
          >部分退货</Button
        >
      </div>
    </div>
    <cancel-order-modal
      @cancleOk="
        isShow = false;
        $emit('saveOrderOk');
      "
      ref="CancelOrderModal"
    />
    <refund-modal ref="RefundModal" @on-success="isShow = false" />
    <return-good-modal ref="ReturnGoodModal" @returnOk="returnOk" />
    <sales-good-modal ref="SalesGoodModal" />
  </Drawer>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import CancelOrderModal from "./cancel-order-modal.vue";
import SalesGoodModal from "./sales-good-modal.vue";
import RefundModal from "./refund-modal.vue";
import ReturnGoodModal from "./return-good-modal.vue";
import { mapActions } from "vuex";
import dayjs from "dayjs";
export default {
  name: "detail-drawer",
  data() {
    return {
      showProductTable: true,
      isShow: false,
      loading: false,
      lockremark: "",
      lockShow: false,
      lockstr: "",
      editIndex: -1, // 当前聚焦的输入框的行数
      obj: {
        OrderStatus: 0,
      },
      productOptions: [
        { title: "商品图片", key: "ProductImg", slot: "Image", width: 120 },
        { title: "商品ID", key: "ProductID", width: 60 },
        { title: "商品编号", key: "Code", width: 100 },
        { title: "商品名称", key: "ProductName", width: 200, tooltip: true },
        { title: "商品分类", key: "ProductClassName", width: 150 },
        { title: "商品单价", key: "UnitPrice", width: 120, align: "center" },
        { title: "商品数量", key: "ProductCount", width: 100, align: "center" },
        { title: "应发数量", key: "ShippingNum", width: 100, align: "center" },
        {
          title: "实发数量",
          key: "RealShippingNum",
          width: 100,
          align: "center",
          slot: "RealShippingNum",
        },
        { title: "已退数量", key: "ReturnNum", width: 100, align: "center" },
        {
          title: "损耗数量",
          key: "LossNum",
          width: 100,
          align: "center",
          slot: "LossNum",
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          align: "center",
          slot: "action",
        },
      ],
      productData: [],
      payLogOptions: [],
      payLogData: [],
      expectBonusData: [],
      refundLogOptions: [
        { title: "退款时间", key: "RefundDate" },
        { title: "物流费", key: "FreightCost" },
        { title: "手续费", key: "HandleCost" },
        { title: "实退金额", key: "RealRefund" },
        { title: "售后单号", key: "ServiceID", slot: "ServiceID" },
        { title: "操作人", key: "MemberCode" },
        { title: "备注", key: "Remark" },
      ],
      expectBonusOptions: [
        { title: "代理编号", key: "MemberCode" },
        { title: "代理姓名", key: "MemberName" },
        { title: "代理等级", key: "Level" },
        { title: "订单编号", key: "BusinessCode" },
        { title: "金额", key: "Amount" },
        { title: "状态", key: "StatusCN" },
        { title: "创建时间", key: "CreateDate" },
      ],
      refundLogData: [],
      expressInfo: [],
      ShowPaylog: true,
      ShowRefund: true,
      ShowExchange: false,
      ShowExpectBonus: false,
      RefundCostScale: 0.0,
    };
  },
  created() {
    this.getProductClassList();
    this.loadConfig();
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.getOrderProductList(this.obj.ID);
        this.GetOrderPayLog(this.obj.OrderCode);
        this.GetOrderRefundLog(this.obj.OrderCode);
        this.GetOrderExpectBonusDetail(this.obj.OrderCode);

        if (this.isEdit) {
          this.GetExpressList();
        }
      }
    },
  },
  methods: {
    ...mapActions(["getProductClassList"]),
    handleEdit(row, index) {
      this.editIndex = index;
    },
    async handleSave(index, row) {
      this.editIndex = -1;
      try {
        const res = await api.EditOrderDetail({
          entity: row,
        });
        if (res.Data === true) {
          this.$Message.success("保存成功");
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    returnOk() {
      this.isShow = false;
      this.$emit("saveOrderOk");
    },
    // 获取订单商品
    async getOrderProductList(id) {
      // TODO 接口要调整
      try {
        const res = await api.GetOrderProductList({
          orderid: id,
        });
        res.Data.forEach((item) => {
          let findproductClass = this.productClass.find(
            (c) => c.Code === item.ClassCode
          );
          item.ClassName = this.productClass
            ? findproductClass
              ? findproductClass.Name
              : ""
            : "";
        });
        this.productData = res.Data;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取订单支付记录
    async GetOrderPayLog(ordercode) {
      try {
        const res = await api.GetOrderPayLog({
          ordercode: ordercode,
        });
        if (res.Data.length > 0) {
          this.ShowPaylog = true;
        } else {
          this.ShowPaylog = false;
        }
        this.payLogOptions = [
          { title: "支付金额", key: "PayMoney", minWidth: 100 },
          // { title: '优惠金额', key: 'DisCountAmount', minWidth: 100 },
          { title: "支付结果", key: "PayStatusCN", minWidth: 100 },
          { title: "支付配置", key: "PayConfigName", minWidth: 150 },
          { title: "支付时间", key: "PayDate", width: "auto", minWidth: 150 },
          { title: "支付备注", key: "Remark", width: "auto", minWidth: 150 },
        ];
        if (this.obj.OrderStatus >= 30) {
          this.payLogOptions.unshift({
            title: "外部流水号",
            key: "PaySerial",
            minWidth: 250,
          });
        }
        this.payLogOptions.unshift({
          title: "内部流水号",
          key: "PayID",
          minWidth: 250,
        });
        this.payLogOptions.unshift({
          title: "支付方式",
          key: "PayTypeCN",
          minWidth: 100,
        });
        // 已退款，部分退款时显示数据
        if (
          res.Data.some((item) => item.PayStatus === 3 || item.PayStatus === 4)
        ) {
          this.payLogOptions = this.payLogOptions.concat([
            { title: "可退金额", key: "CanRefund", minWidth: 100 },
            { title: "已退金额", key: "RealRefund", minWidth: 100 },
            // 暂时不显示
            // { title: '物流', key: 'FreightCost', width: 100 },
            // { title: '手续', key: 'HandleCost', width: 100 },
            { title: "实退金额", key: "RealRefund", minWidth: 100 },
            { title: "退款时间", key: "RefundDate", minWidth: 150 },
          ]);
          // 移除优惠金额
          this.payLogOptions.splice(
            this.payLogOptions.findIndex((item) => item.title === "优惠金额"),
            1
          );
        }
        // 可退款时显示按钮
        if (
          res.Data.some((item) => item.PayStatus === 2 || item.PayStatus === 4)
        ) {
          // if (this.$canAction(["Order_RefundPayLog"])) {
          //   this.payLogOptions = this.payLogOptions.concat([
          //     {
          //       title: "操作",
          //       key: "action",
          //       slot: "action",
          //       fixed: "right",
          //       width: 80,
          //     },
          //   ]);
          // }
        }
        this.payLogData = res.Data;
        this.obj.PayLogList = res.Data;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取退款记录
    async GetOrderRefundLog(ordercode) {
      try {
        const res = await api.GetOrderRefundLog({
          ordercode: ordercode,
        });
        this.refundLogData = res.Data;
        if (res.Data.length > 0) {
          this.ShowRefund = true;
        } else {
          this.ShowRefund = false;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取退款记录
    async GetOrderExpectBonusDetail(ordercode) {
      try {
        const res = await api.GetOrderExpectBonusDetail({
          ordercode: ordercode,
        });
        this.expectBonusData = res.Data;
        if (res.Data.length > 0) {
          this.ShowExpectBonus = true;
        } else {
          this.ShowExpectBonus = false;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取物流配置信息
    async GetExpressList() {
      try {
        const res = await api.GetExpressList({
          Status: 10,
          PageSize: 100,
        });
        if (res.Data) {
          this.expressInfo = res.Data.DataList;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    async handleRefundPayLog(row) {
      this.$refs.RefundModal.formData.payLogID = row.ID;
      this.$refs.RefundModal.formData.logisticsCost = 0; // 物流费
      this.$refs.RefundModal.formData.handleCost = (
        row.PayType === 4 ? 0 : row.CanRefund * this.RefundCostScale
      ).toFixed(2); // 手续费
      this.$refs.RefundModal.formData.CanRefund = row.CanRefund;
      this.$refs.RefundModal.formData.refundAmount = (
        row.CanRefund - this.$refs.RefundModal.formData.handleCost
      ).toFixed(2);
      this.$refs.RefundModal.isShow = true;
    },
    // 导出excel
    handleExportExcel(row) {
      const self = this;
      self.$Modal.confirm({
        title: "导出确认",
        content: "确定要导出Excel吗?",
        loading: true,
        onText: "导出",
        async onOk() {
          // self.$refs.SalesGoodModal.Type = 1;
          // self.$refs.SalesGoodModal.isShow = true;
          try {
            await api.OriginExportExcel({
              ids: row.ID,
              exportName: `发货单${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xls`,
            });
            self.$Modal.remove();
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
    // 选中行样式
    RowClassName(row) {
      return row.Type && row.Type === 30 ? "demo-table-error-row" : "";
    },
    // 打印
    handlePrint(row) {
      const self = this;
      self.$Modal.confirm({
        title: "导出打印",
        content: "确定要打印吗?",
        loading: true,
        onText: "打印",
        async onOk() {
          try {
            await api.RemarkIsExport({ ids: [row.ID] });
            row.OrderDetail = self.productData;
            window.open(
              `${window.location.origin}${window.location.pathname}#/Print`
            );
            self.$Modal.remove();
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
    // 取消订单
    async handleCancel(row) {
      const self = this;
      try {
        // 订单取消前判断
        const res = await api.BeforeCancelOrder({
          orderid: row.ID,
        });
        // 给`退单费用`弹窗赋值
        self.$refs.CancelOrderModal.formData.MemberCode = row.MemberCode;
        self.$refs.CancelOrderModal.formData.OrderCode = row.OrderCode;
        self.$refs.CancelOrderModal.formData.orderid = self.obj.ID;
        self.$refs.CancelOrderModal.formData.sysRemark = res.Msg;
        self.$refs.CancelOrderModal.formData.logisticsCost = 0;
        self.$refs.CancelOrderModal.OrderType = row.OrderType;
        // 不是奖金支付 ，手续费需扣除
        if (self.payLogData.length > 0) {
          let handleCostStr = (
            self.obj.PayType === 4
              ? 0
              : self.payLogData.find((item) => item.PayStatus === 2).CanRefund *
                this.RefundCostScale
          ).toFixed(2);
          self.$refs.CancelOrderModal.formData.handleCost = handleCostStr;
          self.$refs.CancelOrderModal.formData.canRefund = self.payLogData.find(
            (item) => item.PayStatus === 2
          ).CanRefund;
          self.$refs.CancelOrderModal.formData.realRefund = (
            self.payLogData.find((item) => item.PayStatus === 2).CanRefund -
            handleCostStr
          ).toFixed(2);
        }

        if (res.Data) {
          // 接口有返回内容，弹出提示内容
          self.$Modal.confirm({
            title: "取消确认",
            content: res.Data,
            loading: true,
            onOk() {
              self._cancelOrder();
            },
          });
        } else {
          // 取消订单售后流程
          self._cancelOrder();
        }
      } catch (err) {
        this.$Notice.error({ title: "错误", desc: err });
      }
    },
    async loadConfig() {
      try {
        const res = await api.GetSysConfigByKey({
          key: "RefundCostScale",
        });
        this.RefundCostScale = res.Data.Value;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    // 退货
    async handleReturn() {
      this.$refs.ReturnGoodModal.isShow = true;
      let productDataCopy = JSON.parse(JSON.stringify(this.productData));
      productDataCopy.forEach((res) => {
        res.RetiredNum = res.ReturnNum;
        res.ReturnNum = 0;
      });
      this.$refs.ReturnGoodModal.formData.list = productDataCopy;
    },
    // 取消订单售后流程
    _cancelOrder() {
      this.$Modal.remove();
      this.$refs.CancelOrderModal.isShow = true;
    },
  },
  computed: {
    orderStatusEnum() {
      return bizEnum.OrderStatusEnum;
    },
    productClass() {
      return this.$store.state.product.classList || [];
    },
  },
  components: {
    CancelOrderModal,
    RefundModal,
    ReturnGoodModal,
    SalesGoodModal,
  },
};
</script>
<style lang="less">
.order_detail_drawer {
  .drawer-content {
    overflow-x: hidden;
  }
  .refund_table {
    overflow: visible !important;
    .ivu-table-fixed-body {
      overflow: visible !important;
      height: auto !important;
    }
    .ivu-poptip-popper {
      left: -71px !important;
    }
    .ivu-poptip-arrow {
      left: 60% !important;
    }
    .ivu-table .demo-table-error-row td {
      background-color: #ffd591 !important;
      color: rgb(0, 0, 0);
    }
  }
}
</style>
